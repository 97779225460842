import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { SocketService } from '../../services';
import { Subscription } from 'rxjs/Subscription';
import { distinctUntilChanged } from 'rxjs/operators';
import { NgZone } from '@angular/core';

@Component({
  selector: 'app-check-status',
  templateUrl: './check-status.component.html',
  styleUrls: ['./check-status.component.scss']
})
export class CheckStatusComponent implements OnInit, OnDestroy {

  @Input() id;
  @Input() size;
  @Input() initial;
  @Output() changeStatus: EventEmitter<Object> = new EventEmitter<Object>();
  private statuses$: Subscription;
  public status: boolean;

  constructor(
    private socketService: SocketService,
    private ngZone: NgZone
  ) { }

  ngOnInit() {

    this.status = this.initial;

    this.ngZone.runOutsideAngular(() => {

      this.statuses$ = this.socketService.onStatus()
        .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
        .subscribe(statuses => {
          const isThere = statuses.includes(this.id);

          if (this.status !== isThere) {
            this.ngZone.run(() => {
              this.status = isThere;
              this.changeStatus.emit(this.status);
            });
          }
        });
    });

  }

  ngOnDestroy() {
    if (this.statuses$) {
      this.statuses$.unsubscribe();
    }
  }
}
