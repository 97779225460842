import { Injectable } from '@angular/core';
import { FormGroup, FormControl, FormArray, AsyncValidatorFn } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';

@Injectable()
export class ValidateService {

    constructor(
        private apiService: ApiService
    ) { }

    validateAllFormFields(formGroup: FormGroup | FormArray) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if ((control instanceof FormGroup) || (control instanceof FormArray)) {
                this.validateAllFormFields(control);
            }
        });
    }

    whCheckPhonesDuplicate(AC: AbstractControl) {
        const data = AC.value;
        const duplicates = [];
        const duplicates2 = [];

        for (let i = 0; i < data.length; i++) {
            if (data[i]) {
                if (duplicates.includes(data[i])) {

                    duplicates2.push(data[i]);

                    if (AC['duplicate'] != duplicates2) {
                        AC['duplicate'] = duplicates2;
                    }

                } else {

                    duplicates.push(data[i]);
                }
            }
        }

        return null;
    }

    emailValidation(AC: AbstractControl) {
      const control = AC.value;

      const regex = /^[a-zA-Z0-9]+([._-][a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;

      if (!control || control.endsWith('@sidly') || regex.test(control)) {
        return null;
      } else {
        return { emailValidation: true };
      }
    }

    dateValidator(c: AbstractControl): { [key: string]: boolean } {
        let value = c.value;
        if (value && typeof value === "string") {
            let match = value.match(/^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/);
            if (!match) {
                return { 'dateInvalid': true };
            } else if (match && match[0] !== value) {
                return { 'dateInvalid': true };
            }
        }
        return null;
    }

    matchPassword(AC: AbstractControl) {
        const password = AC.get('password').value; // to get value in input tag
        const confirmPassword = AC.get('password2').value; // to get value in input tag
        if (password !== confirmPassword) {
            AC.get('password2').setErrors({ matchPassword: true });
        } else {
            AC.get('password2').setErrors(null);
            return null;
        }
    }

    matchPhones(AC: AbstractControl) {
        const number = AC.get('number').value;
        const confirmNumber = AC.get('repeatNumber').value;
        if (number !== confirmNumber) {
            AC.get('repeatNumber').setErrors({ matchPhones: true });
        } else {
            AC.get('repeatNumber').setErrors(null);
            return null;
        }
    }

    checkCharsInPassword(AC: AbstractControl) {
        if (AC.get('password').value.indexOf(' ') === -1) {
            return null;
        } else {
            AC.get('password').setErrors({ checkCharsInPassword: true });
        }
    }

    passwordStrong(AC: AbstractControl) {
        const control = AC.value;

        const hasNumber = /\d/.test(control);
        const hasUpper = /[A-Z]/.test(control);
        const hasLower = /[a-z]/.test(control);

        if (!hasNumber) {
            return { numberRequired: true };
        } else if (!hasUpper) {
            return { upperRequired: true };
        } else if (!hasLower) {
            return { lowRequired: true };
        }

        return null;
    }

    minValidator(min: number) {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const input = Number(control.value);
            return isNaN(input) || input >= min ? null : { minIntValue: true };
        };
    }

    limitZerosValidator(maxZeros: number) {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value = control.value ? control.value.toString() : '';
            const zerosCount = value.length;
            return zerosCount > maxZeros ? { limitZeros: { limitZeros: true, maxZeros: maxZeros } } : null;
        };
    }

    minLength(minChars: number) {
      return (control: AbstractControl): { [key: string]: any } | null => {
        const value = control.value ? control.value.toString() : '';
        const actualLength = value.length;
        return actualLength < minChars ? { minLengthV2: { minLengthV2: true, minChars: minChars } } : null;
      };
    }

  minValue(minValue: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value ? parseFloat(control.value) : NaN;
      return isNaN(value) || value < minValue ? { minValueValidator: { minValueValidator: true, minValue: minValue } } : null;
    };
  }


  maxValue(maxValue: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value ? parseFloat(control.value) : NaN;
      return isNaN(value) || value > maxValue ? { maxValueValidator: { maxValueValidator: true, maxValue: maxValue } } : null;
    };
  }

  maxLength(maxChars: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value ? control.value.toString() : '';
      const actualLength = value.length;
      return actualLength > maxChars ? { maxLengthV2: { maxLengthV2: true, maxChars: maxChars } } : null;
    };
  }


  passwordNumberReq(AC: AbstractControl) {
        const control = AC.value;

        if (!(/\d/.test(control))) {
            return { numberRequired: true };
        }
    }

    passwordUpperReq(AC: AbstractControl) {
        const control = AC.value;

        if (!/[A-Z]/.test(control)) {
            return { upperRequired: true };
        }
    }

    passwordLowReq(AC: AbstractControl) {
        const control = AC.value;

        if (!(/[a-z]/.test(control))) {
            return { lowRequired: true };
        }
    }

    passwordLength(AC: AbstractControl) {
        const control = AC.value;

        if (control.length < 8) {
            return { passwordMinLength: true };
        }
    }

    checkHourFormat(AC: AbstractControl) {
        const control = AC.value;

        const pattern = /^([0-9][0-9]:[0-9][0-9])$/.test(control);

        if (control.length > 0) {

            if (control.length !== 5 || control[2] !== ':' || !pattern) {
                return { checkHoursFormat: true };
            } else {
                const hour = control[0] + control[1];
                const minutes = control[3] + control[4];

                if (!(hour >= 0 && hour < 24 && minutes >= 0 && minutes < 60)) {
                    return { badHoursFormat: true };
                } else if (hour === '23' && minutes === '59') {
                    return null;
                } else if (minutes % 15 !== 0) {
                    return { FifteenMinutesFormat: true };
                }
            }

        }

        return null;
    }

    checkHourFormatForEdit(AC: AbstractControl) {
        const control = AC.value;

        if (control) {
            const pattern = /^([0-9][0-9]:[0-9][0-9])$/.test(control);

            if (control.length > 0) {

                if (control.length !== 5 || control[2] !== ':' || !pattern) {
                    return { checkHoursFormat: true };
                } else {
                    const hour = control[0] + control[1];
                    const minutes = control[3] + control[4];

                    if (!(hour >= 0 && hour < 24 && minutes >= 0 && minutes < 60)) {
                        return { badHoursFormat: true };
                    }

                }

            }
        }

        return null;
    }


    checkHourGreater(AC: AbstractControl) {
        const control = AC.value;

        if (AC.get('startHour').status === 'VALID' && AC.get('endHour').status === 'VALID' &&
            AC.get('startSecondHour').status === 'VALID' && AC.get('endSecondHour').status === 'VALID') {
            if (control.startHour >= control.endHour && control.enabled1 ||
                control.startSecondHour >= control.endSecondHour && control.enabled2) {
                return { badStartHoursFormat: true };
            } else if (
                control.enabled1 && control.enabled2 && (
                    control.startHour >= control.startSecondHour && control.startHour <= control.endSecondHour ||
                    control.endHour >= control.startSecondHour && control.endHour <= control.endSecondHour ||
                    control.startSecondHour >= control.startHour && control.startSecondHour <= control.endHour ||
                    control.endSecondHour >= control.startHour && control.endSecondHour <= control.endHour
                )
            ) {
                return { badHoursFormat: true };
            }
        }

        return null;
    }

  checkHourEqualNightMode(AC: AbstractControl) {
    const nightStart = AC.get('night_start').value;
    const nightEnd = AC.get('night_stop').value;
    if (nightStart && nightEnd && nightStart === nightEnd && nightStart != '__:__') {
      return { equalStartAndEndHours: true };
    }

    return null;

  }

  checkHourGreaterEditUs(AC: AbstractControl) {
        const control = AC.value;

        if (AC.get('night_start').status === 'VALID' && AC.get('night_stop').status === 'VALID') {
            if (control.night_start >= control.night_stop) {
                return { badStartHoursFormat: true };
            }
        }

        return null;
    }



    checkCharsInNewPassword(AC: AbstractControl) {
        const password = AC.value;

        if (password.indexOf(' ') === -1) {
            return null;
        }
        return { checkCharsInNewPassword: true };

    }

    imeiValidation(AC: AbstractControl) {
      const imei = AC.value;

      if (!imei) {
        return { required: true };
      }

      if (imei < 100000000000000 || imei > 999999999999999) {
        return { imeiValidation: true };
      }

      return null;
    }

  peselValidation(AC: AbstractControl) {
        let pesel = AC.value;
        if (typeof pesel !== 'string')
            return { wrongPesel: true }

        let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
        let sum = 0;
        let controlNumber = parseInt(pesel.substring(10, 11));

        for (let i = 0; i < weight.length; i++) {
            sum += (parseInt(pesel.substring(i, i + 1)) * weight[i]);
        }
        sum = sum % 10;
        if ((10 - sum) % 10 != controlNumber)
            return { wrongPesel: true }

        return null;
    }

    checkPhonesDuplicate(AC: AbstractControl) {
        const data = AC.value;
        const duplicates = [];
        const duplicates2 = [];

        for (let i = 0; i < data.length; i++) {
            if (data[i].number) {
                if (duplicates.includes(data[i].number)) {

                    duplicates2.push(data[i].number);

                    if (AC['duplicate'] != duplicates2) {
                        AC['duplicate'] = duplicates2;
                    }

                } else {

                    duplicates.push(data[i].number);
                }
            }
        }

        return null;
    }

    checkHoursDuplicate(AC: AbstractControl) {
        const data = AC.value;
        const sorted_data = data.slice(0).sort((a, b) => {
            if (a.hour < b.hour) {
                return 1;
            }
            if (a.hour > b.hour) {
                return -1;
            }
        });

        let results = [];
        for (let i = 0; i < sorted_data.length - 1; i++) {
            if (sorted_data[i].hour !== null && sorted_data[i].hour !== '') {
                if (sorted_data[i + 1].hour === sorted_data[i].hour) {
                    return { checkHoursDuplicate: true };
                }
            }
        }
    }

    checkLengthName(AC: AbstractControl) {
        const control = AC.value;

        const data = control.split(' ');

        for (let i = 0; i < data.length; i++) {
            if (data[i].length > 20) {
                return { checkLengthName: true };
            }
        }
        return null;
    }

    checkAreaCode(AC: AbstractControl) {
        const control = AC.value;

        if (control && control.length > 0) {
            if (control[0] !== '+') {
                return { checkAreaCode: true };
            }
        }
        return null;
    }

    checkMaximumPulse(AC: AbstractControl) {
        const control = AC.value;

        if (AC.get('minimum') && AC.get('maximum')) {
            if (control.minimum >= control.maximum) {
                AC.get('maximum').setErrors({ checkMaximumPulse: true });
            } else {
                AC.get('maximum').setErrors(null);
            }
        }
        return null;
    }

    checkMaxHr(AC: AbstractControl) {
        const control = AC.value;

        if (AC.get('minHr') && AC.get('maxHr')) {
            if (control.minHr >= control.maxHr) {
                AC.get('maxHr').setErrors({ checkMaxHr: true });
            } else {
                AC.get('maxHr').setErrors(null);
            }
        }
        return null;
    }

    checkSingleQuote(AC: AbstractControl) {
        let newText = AC.value;

        if (newText != undefined) {
            if (typeof newText == "string") {
                if (newText.includes('\''))
                    return { forbiddenChar: true }

                if (newText.includes('\"'))
                    return { forbiddenChar: true }

                if (newText.includes('\/'))
                    return { forbiddenChar: true }

                if (newText.includes('\\'))
                    return { forbiddenChar: true }
            }
        }
        return null;
    }


}
