import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-show-avatar',
  templateUrl: './show-avatar.component.html',
  styleUrls: ['./show-avatar.component.scss']
})
export class ShowAvatarComponent implements OnInit {
  @Input() link: string;
  @Input() size: string;
  @Input() role: string;
  @Input() imei: string;
  @Input() type: string;
  @Input() resizePlanner = false;
  @Input() colour: string;
  @Input() border = false;

  isPlannerImei: boolean = false;
  isVitalBand: boolean = null;
  isOneType: boolean = false;

  get avatar() {
    return `${environment.avatar_url}${this.link}`;
  }

  constructor() { }

  ngOnInit() {
    if (this.imei) {
      this.isPlannerImei = this.imei.startsWith('644');
      this.isVitalBand = this.type === 'VITAL CARE';
      this.isOneType = this.type === 'ONE';
    }
  }
}
