import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../models';
import { PatientService, UserService, SocketService, ModalService, CaregiverService, NotificationService } from '../../services';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-new-header',
  templateUrl: './new-header.component.html',
  styleUrls: ['./new-header.component.scss']
})
export class NewHeaderComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  public currentUser: User;
  public avatar;
  public type: string;
  public lang = '';
  public apiUrl;
  public gotEnableTraits;
  public gotEkoPrivilege: boolean = false;
  public gotEkoInterPrivilege: boolean = false;
  public managerGotAssignResponderPrivilege = false;
  public gotPrivilegeAssignResponder = false;
  public isSubmitting = false;
  public managerDetails = null;
  public responderTime: number = 10000;
  public intervalId: any;
  responders: any[] = [];
  telecaregivers: any[] = [];
  basicResponders: any[] = [];
  reserveResponders: any[] = [];

  @ViewChild('caregivers') caregivers: ElementRef;
  @ViewChild('users') users: ElementRef;
  @ViewChild('settings') settings: ElementRef;
  @ViewChild('caregiversArrow') caregiversArrow: ElementRef;
  @ViewChild('usersArrow') usersArrow: ElementRef;
  @ViewChild('settingsArrow') settingsArrow: ElementRef;
  @ViewChild('basicGroup') basicGroup: ElementRef;
  @ViewChild('basicGroupArrow') basicGroupArrow: ElementRef;
  @ViewChild('reserveGroup') reserveGroup: ElementRef;
  @ViewChild('reserveGroupArrow') reserveGroupArrow: ElementRef;

  constructor(
    private userService: UserService,
    private patientService: PatientService,
    public router: Router,
    public translate: TranslateService,
    private socketService: SocketService,
    private modalService: ModalService,
    private caregiverService: CaregiverService,
    private notificationService: NotificationService,
  ) {
    translate.addLangs(['en', 'pl', 'de', 'cs', 'fr', 'pt', 'it', 'ro', 'da', 'ar', 'bg', 'sr', 'no', 'nl', 'et', 'lv', 'sl', 'es']);

    if (window.localStorage['lang']) {
      translate.use(window.localStorage['lang']);
    } else {
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/en|pl|de|cs|fr|pt|it|ro|da|ar|bg|sr|no|nl|ee|lv|sl|es/) ? browserLang : 'en');
    }
    this.lang = window.localStorage['lang'];
  }

  ngOnInit() {
    this.apiUrl = environment.avatar_url;

    this.userService.currentUser.subscribe(
      (userData) => {
        this.currentUser = userData;
        this.type = this.userService.getTypeOfUser();

        if (this.currentUser.user_id != undefined && this.currentUser.user_id.privilege != undefined && this.currentUser.user_id.privilege.includes('enableTraits')) {
          this.gotEnableTraits = true;
        }

        if (this.currentUser.user_id.privilege != undefined && this.currentUser.user_id.privilege.includes('eko') && !this.currentUser.user_id.privilege.includes('ekoInter')) {
          this.gotEkoPrivilege = true;
        }
        if (this.currentUser.user_id != undefined && this.currentUser.user_id.privilege != undefined && this.currentUser.user_id.privilege.includes('ekoInter')) {
          this.gotEkoInterPrivilege = true;
        }
        if (this.currentUser.user_id != undefined && this.currentUser.user_id.privilege != undefined && this.currentUser.user_id.privilege.includes('assignResponder')) {
          this.gotPrivilegeAssignResponder = true;
        }
        else { this.getManagerDetails(); }
        if (Object.keys(userData).length > 0) {
          this.patientService.getAvatar(userData.user_id.id).subscribe(data => {
            this.avatar = data;
          });
        }
      }
    );
    this.getResponderState();

    this.socketService.sendSignal().pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.getResponderState();
    });


  }

  shouldActivateDropdown(): boolean {
    const url = this.router.url;
    // return url.includes('/caregivers') || url === '/users' || url === '/settings';
    return true;
  }

  getManagerDetails() {
    if (this.currentUser && this.currentUser.user_id && this.currentUser.user_id.id) {
      this.userService.getManagerPrivilege(this.currentUser.user_id.id).subscribe(
        response => {
          if (response) {
            this.managerDetails = response;
            if (this.managerDetails !== 'no-data' && this.managerDetails.data) {
              if (this.managerDetails.data.includes('assignResponder')) {
                this.managerGotAssignResponderPrivilege = true;
              }
            }
          }
        }, () => {}
      );
    }
  }


  responderEndIntervention(imei: string, firstName: string, lastName: string) {
    this.modalService
      .openAccept(this.translate.instant("USER.RESPONDER.ARE-YOU-SURE-TO-END-INTERVENTION", { firstName: firstName, lastName: lastName }))
      .subscribe((confirmation) => {
        if (confirmation.ok === true) {
          this.isSubmitting = true;

          this.userService.responderEndIntervention(imei).subscribe(
            () => {
              this.modalService.open(this.translate.instant('USER.RESPONDER.INTERVENTION-ENDED'));
              this.isSubmitting = false;
            },
            () => {
              this.modalService.open(this.translate.instant('USER.RESPONDER.ERROR-END-INTERVENTION'));
              this.isSubmitting = false;
            }
          );
        }
      });
  }

  getResponderState() {
    this.userService.getResponderState().subscribe(
      data => {
        this.responders = data.data;
        this.basicResponders = this.responders.filter(rs => rs.isResponderBasic === '1');
        this.reserveResponders = this.responders.filter(rs => rs.isResponderBasic === '0' || rs.isResponderBasic == null || rs.isResponderBasic === "---");

        if (this.responders.some(rs => rs.status === '1')) {
          this.startResponderInterval();
        }
      },
      () => { }
    );

  }

  startResponderInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.intervalId = setInterval(() => {
      const uniqueAlarmIds = new Set(this.responders.filter(rs => rs.status === '1').map(rs => rs.alarmId));
      if (uniqueAlarmIds.size > 0) {
        uniqueAlarmIds.forEach(alarmId => {
          this.notificationService.sentActionsCheck(alarmId).subscribe(
            () => { },
            () => { }
          );
        });
      } else {
        clearInterval(this.intervalId);
      }
    }, this.responderTime);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  public changeLanguage(lang: string): void {
    this.translate.use(lang);
    window.localStorage['lang'] = lang;
    this.lang = window.localStorage['lang'];
  }

  logout() {
    this.userService.logout().subscribe(
      data => {
        this.userService.purgeAuth();
        this.router.navigateByUrl('/auth/login');
        setTimeout(() => {
          window.location.reload(false);
        }, 100);
      },
      () => {
        this.userService.purgeAuth();
        this.router.navigateByUrl('/auth/login');
        setTimeout(() => {
          window.location.reload(false);
        }, 100);
      }
    );
  }

  toggleDropdown(elem, arrow) {
    if (elem.classList.value.includes('active')) {
      elem.classList.remove('active');
      arrow.classList.remove('active');
    } else {
      elem.classList.add('active');
      arrow.classList.add('active');
    }
  }

  formatName(firstName: string, lastName: string): string {
    const maxLength = 18;
    let formattedName = `${lastName} ${firstName}`;

    if (formattedName.length > maxLength) {
      formattedName = formattedName.substr(0, maxLength - 3) + '...';
    }

    return formattedName;
  }

}
