import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { User } from '../models';
import { PatientService, UserService } from '../services';
import { Router } from "@angular/router";
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/fromEvent';

@Component({
  selector: 'app-layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @ViewChild('menu') menu;
  public isCollapsed: boolean;
  private subscription: Subscription;
  public currentUser: User;
  public avatar;
  public lang = '';

  constructor(
    private userService: UserService,
    public translate: TranslateService,
    private eRef: ElementRef,
    public router: Router,
    private patientService: PatientService,
    private translateService: TranslateService
  ) {
    translate.addLangs(['en', 'pl', 'de', 'cs', 'fr', 'pt', 'it', 'ro', 'da', 'ar', 'bg', 'no', 'nl', 'sr', 'et', 'lv', 'sl', 'es']);

    if (window.localStorage['lang']) {
      translate.use(window.localStorage['lang']);
    } else {
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/en|pl|de|cs|fr|pt|it|ro|da|ar|bg|no|nl|sr|ee|lv|sl|es/) ? browserLang : 'en');
    }
    this.lang = window.localStorage['lang'];


    this.isCollapsed = true;
  }


  ngOnInit() {
    this.userService.currentUser.subscribe(
      (userData) => {
        this.currentUser = userData;
        if (Object.keys(userData).length > 0) {
          this.patientService.getAvatar(userData.user_id.id).subscribe(data => {
            this.avatar = data;
          });
        }
      }
    );

    this.subscription = Observable.fromEvent(document, 'click').subscribe((event: KeyboardEvent) => {
      if (!this.isCollapsed) {
        if (!this.eRef.nativeElement.contains(event.target)) {
          this.closeMenu();
        }
      }
    });
  }

  public changeLanguage(lang: string): void {
    this.translate.use(lang);
    window.localStorage['lang'] = lang;
    this.lang = window.localStorage['lang'];
  }

  public closeMenu(): void {
    if (!this.isCollapsed) {
      this.isCollapsed = true;
    }
  }

  public ifContainsStart(text) {
    return !(text.includes('/start') || text.includes('/indiv-client/add-user') || text.includes('/indiv-client/accept-documents'));

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  logout() {
    this.userService.logout().subscribe(
      data => {
        this.userService.purgeAuth();
        location.reload(true);
      },
      err => {
        this.userService.purgeAuth();
        location.reload(true);
      }
    );
  }
}
