import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SocketService } from '../../services/socket.service';
import { NotificationService } from '../../services/notification.service';
import { PatientService } from '../../services/patient.service';
import { Subscription } from 'rxjs';
import { NgZone } from '@angular/core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-sos-alert',
  templateUrl: './sos-alert.component.html',
  styleUrls: ['./sos-alert.component.scss']
})
export class SosAlertComponent implements OnInit, OnDestroy {

  @Input() user: any;
  private subscriptions = new Subscription();
  public alarm: any;
  public typeOfBand: string | undefined;

  constructor(
    public activeModal: NgbActiveModal,
    private socketService: SocketService,
    private notificationService: NotificationService,
    private patientService: PatientService,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.ngZone.runOutsideAngular(() => {
      this.loadPatientData();
      this.loadAlarmData();
      this.listenToSocket();
    });
  }

  private loadPatientData(): void {
    const patientSub = this.patientService.getPatient(this.user.imei)
      .subscribe({
        next: (data) => {
          this.typeOfBand = data.type;
        },
        error: (err) => {
        }
      });

    this.subscriptions.add(patientSub);
  }

  private loadAlarmData(): void {
    const alarmSub = this.notificationService.getAlarmUsers()
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          if (Array.isArray(data) && data.length > 0) {
            const index = data.findIndex(j => j.alarmID === this.user.alarmID);
            this.alarm = data[index] || null;
          }
        },
        error: (err) => {
        }
      });

    this.subscriptions.add(alarmSub);
  }

  private listenToSocket(): void {
    const socketSub = this.socketService.onAccept()
      .subscribe({
        next: (data) => {
          this.ngZone.run(() => {
            if (data.id === this.user.id) {
              this.activeModal.dismiss();
            }

            setTimeout(() => {
              this.socketService.checkAlarms();
            }, 1500);
          });
        },
        error: (err) => {
        }
      });

    this.subscriptions.add(socketSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
