import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { User, UserService } from './shared';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  public lang = '';
  public currentUser: User;
  public hamburgerOpened: boolean = false;
  public isCurrentUser = false;
  @ViewChild('appNewHeader') appNewHeader: ElementRef;

  constructor(
    private userService: UserService,
    public translate: TranslateService,
    public router: Router
  ) {
    translate.addLangs(['en', 'pl', 'de', 'cs', 'fr', 'pt', 'it', 'ro', 'da', 'ar', 'bg', 'no', 'nl', 'sr', 'et', 'lv', 'sl', 'es']);

    if (window.localStorage['lang']) {
      translate.use(window.localStorage['lang']);
    } else {
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/en|pl|de|cs|fr|pt|it|ro|da|ar|bg|no|nl|sr|ee|lv|sl|es/) ? browserLang : 'en');
      window.localStorage['lang'] = browserLang;
    }
    this.lang = window.localStorage['lang'];

    router.events.subscribe(() => {

      if (this.appNewHeader != undefined) {
        this.appNewHeader.nativeElement.classList.remove('expanded');
      }

      this.hamburgerOpened = false;
    });

  }

  public changeLanguage(lang: string): void {
    window.localStorage['lang'] = lang;
    this.lang = window.localStorage['lang'];
    location.reload(false);
  }

  ngOnInit() {
    this.userService.populate();
    const inter = setInterval(() => {
      this.currentUser = this.userService.getCurrentUser();
      if (this.currentUser.hasOwnProperty('user_id')) {
        clearInterval(inter);
        this.isCurrentUser = true;
      }
    }, 100);
  }

  onDeactivate() {
    document.body.scrollTop = 0;
  }

  toggleMenu() {
    this.appNewHeader.nativeElement.classList.toggle('expanded');
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.hamburgerOpened = !this.hamburgerOpened;
  }
}
